function onReady(fn) {
    // see if DOM is already available
    if (document.readyState === "complete" || document.readyState === "interactive") {
        // call on next available tick
        setTimeout(fn, 1);
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
}

onReady(function() {
    const body = document.querySelector('body');
    setTimeout(function() {
        body.classList.toggle('-night');
    }, 1500)
});
